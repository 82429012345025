.icon-wrapper {
 position: relative;
}

.icon {
 display: flex;
 align-items: center;
 justify-content: space-around;
 width: $icon-height;
 height: $icon-height;
 justify-content: space-around;
 border-radius: 50%;
 background-color: $white;

 svg {
  stroke: $black;
 }

 p {
  color: $black;
  padding-left: 0;
  @include fw-xl;
 }
}

.icon-border {
 position: absolute;
 top: 0;
 left: 0;
}