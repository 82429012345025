.about {
 position: relative;
 z-index: 0;

 .wrapper {
  @include vertical-section-space(padding-top);
  @include vertical-section-space(padding-bottom);
  @include horizontal-section-space(padding-left);
  @include horizontal-section-space(padding-right);

  .grid-about {
   .item {
    flex-basis: 27%;

    @media (max-width: $tablet) {
     flex-basis: 48%;
    }

    @media (max-width: $mobile) {
     flex-basis: 100%;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
     padding-bottom: 50px;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
     @media (max-width: $mobile) {
      padding-bottom: 50px;
     }
    }
   }
  }
 }
}