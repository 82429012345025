// colours

$green: #32cd32;
$white: #FFFFFF;
$black: #191919;
$grey: #EEF0F2;
$grey-dark: #7C7C7C;

$desktop-sm: 1100px;
$tablet: 800px;
$mobile: 500px;

// other

$icon-height: 55px;
$nav-height: 80px;
$covid-height: 60px;
$border-radius: 3px;
$line-width: 2px;