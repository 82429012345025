@import 'assets/sass/variables.scss';
@import 'assets/sass/mixins.scss';
@import 'assets/sass/text.scss';
@import 'app/components/button/button.scss';
@import 'app/components/heading/heading.scss';
@import 'app/components/grid/grid.scss';
@import 'app/components/logo/logo.scss';
@import 'app/components/icon/icon.scss';
@import 'app/components/nav/nav.scss';
@import 'app/sections/header/header.scss';
@import 'app/sections/covid/covid.scss';
@import 'app/sections/about/about.scss';
@import 'app/sections/cta/cta.scss';
@import 'app/sections/services/services.scss';
@import 'app/sections/pricing/pricing.scss';
@import 'app/sections/contact/contact.scss';
@import 'app/sections/footer/footer.scss';

*,
*::after,
*::before {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

body {
 position: relative;
 font-family: 'Montserrat', sans-serif;
 background-color: $white;
}

.max-width {
 position: relative;
 margin: 0 auto;
 max-width: 1440px;
 height: 100%;
}

// text

h1,
h2,
h3 {
 span {
  color: $green !important;
 }
}

a {
 color: inherit;
 text-decoration: none;
}

li {
 list-style: none;
}
