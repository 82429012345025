.pricing {
 position: relative;
 z-index: 0;
 background-color: $black;

 * {
  color: $white;
 }

 .wrapper {
  @include vertical-section-space(padding-top);
  @include vertical-section-space(padding-bottom);
  @include horizontal-section-space(margin-left);
  @include horizontal-section-space(margin-right);
  position: relative;
  overflow: hidden;

  .section-heading {
   margin-bottom: #{$icon-height * 0.5};

  }

  .grid-pricing {
   position: relative;
   z-index: 1;
   overflow: hidden;
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   margin-top: #{$icon-height * -0.5};

   .item {
    position: relative;
    flex-basis: 21%;
    padding-bottom: 80px;

    @media (max-width: $tablet) {
     display: flex;
     flex-basis: 100%;
     text-align: left;
    }

    .icon {
     background-color: transparent;
     position: relative;
     z-index: 1;

     p {
      color: $white;

      &::after {
       content: '';
       position: absolute;
       z-index: -1;
       top: 3px;
       left: 2px;
       right: 0;
       bottom: 2px;
       background-color: $black;
       border-radius: 50%;
      }
     }
    }

    .text {
     @media (max-width: $tablet) {
      padding-left: 25px;
     }
    }

    .btn {
     @include btn-base-styles;
     border-color: $green;
     // background-color: $green;
     position: absolute;
     bottom: 0;
     left: 0;

     @media (max-width: $tablet) {
      left: 25px + $icon-height;
     }
    }

    &:not(:last-child) {
     @media (max-width: $tablet) {
      padding-bottom: 70px;
      margin-bottom: 40px;
     }
    }
   }
  }

  .timeline {
   position: relative;
   z-index: 0;
   height: $line-width;
   background-color: $green;
   width: 80%;
   margin: 0 auto 0 0;

   @media (max-width: $tablet) {
    display: none;
   }
  }
 }
}