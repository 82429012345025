// font weights

@mixin fw-xl {
 font-weight: 700;
}

@mixin fw-lg {
 font-weight: 600;
}

@mixin fw-md {
 font-weight: 500;
}

@mixin fw-sm {
 font-weight: 300;
}

// text padding

@mixin text-spacing($property, $value) {
 #{$property}: $value;

 @media (max-width: $tablet) {
  #{$property}: #{$value * 0.75};
 }
}

// text

h1 {
 $font-size: 65px;
 font-size: $font-size;
 line-height: #{$font-size * 1.3};
 @include fw-xl;
 @include text-spacing(padding-bottom, 15px);
 color: $black;

 @media (max-width: $tablet) {
  $font-size: 50px;
  font-size: $font-size;
  line-height: #{$font-size * 1.2};
 }
}

h2 {
 $font-size: 48px;
 font-size: $font-size;
 line-height: #{$font-size * 1.3};
 @include text-spacing(padding-bottom, 12px);
 @include fw-xl;
 color: $black;

 @media (max-width: $tablet) {
  $font-size: 40px;
  font-size: $font-size;
  line-height: #{$font-size * 1.3};
 }
}

h3 {
 $font-size: 38px;
 font-size: $font-size;
 line-height: #{$font-size * 1.3};
 @include text-spacing(padding-bottom, 12px);
 @include fw-lg;
 color: $black;

 @media (max-width: $tablet) {
  $font-size: 34px;
  font-size: $font-size;
  line-height: #{$font-size * 1.3};
 }
}

h4 {
 $font-size: 22px;
 font-size: $font-size;
 line-height: #{$font-size * 1.3};
 @include text-spacing(padding-bottom, 12px);
 @include fw-lg;
 color: $black;

 @media (max-width: $tablet) {
  $font-size: 20px;
  font-size: $font-size;
  line-height: #{$font-size * 1.3};
 }
}

h5 {
 $font-size: 19px;
 font-size: $font-size;
 @include text-spacing(padding-bottom, 25px);
 @include fw-lg;
 color: $black;
}



@mixin p-xl {
 $font-size: 21px;
 font-size: $font-size;
 line-height: #{$font-size * 1.7};

 @media (max-width: $tablet) {
  $font-size: 19px;
  font-size: $font-size;
  line-height: #{$font-size * 1.7};
 }
}

@mixin p-lg {
 $font-size: 17px;
 font-size: $font-size;
 line-height: #{$font-size * 1.7};

 @media (max-width: $tablet) {
  $font-size: 15px;
  font-size: $font-size;
  line-height: #{$font-size * 1.7};
 }
}

@mixin p-md {
 $font-size: 15px;
 font-size: $font-size;
 line-height: #{$font-size * 1.7};
}

@mixin p-sm {
 $font-size: 14px;
 font-size: $font-size;
 line-height: #{$font-size * 1.7}
}


@mixin terms {
 $font-size: 13px;
 font-size: $font-size;
 line-height: #{$font-size * 1.7};

 a {
  text-decoration: underline;
 }
}

p {
 @include fw-sm;
 @include text-spacing(padding-bottom, 15px);
 color: $black;
}