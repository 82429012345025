.contact {
 position: relative;
 z-index: 0;

 .wrapper {
  @include vertical-section-space(padding-top);
  @include vertical-section-space(padding-bottom);
  @include horizontal-section-space(padding-left);
  @include horizontal-section-space(padding-right);

  .content {
   display: flex;
   align-items: flex-start;
   flex-wrap: wrap;
   justify-content: space-between;

   .grid-contact {
    flex-basis: 45%;

    @media (max-width: $desktop-sm) {
     flex-basis: 100%;
    }

    .item {
     flex-basis: 100%;
     border-radius: 5px;
     @include text-spacing(padding-bottom, 50px);

     a {
      display: block;
      @include p-lg;
      @include fw-sm;

      @media (max-width: $tablet) {
       font-size: 14px;
       line-height: 14px;
      }

      span {
       @include fw-sm;

       @media (max-width: $tablet) {
        font-size: inherit;
        line-height: inherit;
       }
      }
     }

     &:first-child {
      a {
       &:last-child {
        margin-top: 8px;

        @media (max-width: $mobile) {
         margin-top: 15px;
        }
       }
      }
     }

     &:last-child {
      padding-bottom: 0;
     }
    }
   }

   .form-wrapper {
    flex-basis: 55%;
    border-radius: 3px;

    @media (max-width: $desktop-sm) {
     @include layout(margin-top, 80px);
    }

    @include layout(padding-top, 40px);
    @include layout(padding-bottom, 40px);
    @include layout(padding-right, 50px);
    @include layout(padding-left, 50px);
    background-color: $grey;

    @media (max-width: $desktop-sm) {
     flex-basis: 100%;
    }

    @media (max-width: $mobile) {
     @include layout(padding-right, 30px);
     @include layout(padding-left, 30px);
    }

    form {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;

     .field {
      flex-basis: 100%;
      @include layout(padding-bottom, 25px);

      label,
      input,
      textarea {
       display: block;
       @include p-md;
       outline: none;
      }

      label {
       @include text-spacing(padding-bottom, 5px);
       color: $grey-dark;

       @media (max-width: $tablet) {
        font-size: 14px;
        line-height: 14px;
       }
      }

      input,
      textarea {
       @include p-md;
       width: 100%;
       border: none;
       border-radius: 5px;
       padding: 10px 15px;
       border: 2px $grey solid;
       font-family: 'Montserrat', sans-serif;

       &:focus {
        border: 2px $green solid;
       }
      }

      textarea {
       min-height: 100px;
      }

      &.name {
       flex-basis: 48%;

       @media (max-width: $tablet) {
        flex-basis: 100%;
       }
      }

      &.terms {
       @include layout(padding-bottom, 20px);

       p {
        @include terms;
        padding: 0;
       }
      }
     }

     .button-wrapper {
      flex-basis: 100%;
      text-align: center;

      .btn {
       border-color: $green;
       background-color: $green;

       p {
        font-family: 'Montserrat', sans-serif;
       }
      }
     }
    }
   }
  }
 }
}