.grid {
 display: flex;
 justify-content: space-between;
 flex-wrap: wrap;

 h4 {
  @media (max-width: $tablet) {
   padding-bottom: 15px;
  }
 }

 .item {
  text-align: left;

  .icon-wrapper {
   display: inline-block;
   @include text-spacing(margin-bottom, 20px);

   @media (max-width: $tablet) {
    margin-bottom: 20px;
   }
  }

  a,
  p {
   @include p-md;
   padding: 0;

   span {
    @include fw-lg;
   }
  }
 }
}