.cta {
 position: relative;
 z-index: 0;
 overflow: hidden;
 background-color: $grey;

 .wrapper {
  @include layout(padding-top, 70px);
  @include layout(padding-bottom, 70px);
  @include horizontal-section-space(padding-left);
  @include horizontal-section-space(padding-right);
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;

  * {
   color: $black;
  }

  .text {
   flex-basis: 50%;

   @media (max-width: 1200px) {
    @include layout(padding-bottom, 70px);
   }

   .desc {
    @include p-lg;
    @include layout(padding-bottom, 25px);
   }

   .btn {
    border-color: $green;
   }
  }

  .animation-wrapper {
   position: relative;
   flex-basis: 45%;
  }
 }

 &.coverage {
  $desktop-width: 450px;
  $mobile-width: 250px;

  .text {
   @media (max-width: 1200px) {
    flex-basis: 100%;
   }
  }

  .animation-wrapper {
   @media (max-width: 1200px) {
    flex-basis: 100%;
   }

   .animation {
    position: relative;
    width: $desktop-width;
    margin: 0 auto;
    padding: 5px 0 40px 0;
    overflow: hidden;

    @media (max-width: $tablet) {
     width: $mobile-width;
     padding: 5px 0 20px 0;
    }

    .van {
     position: relative;
     z-index: 1;

     .van-body {
      width: 70%;
      display: block;
      margin: 0 auto;
     }

     .wheel {
      position: absolute;
      bottom: -20px;

      @media (max-width: $tablet) {
       width: 30px;
       bottom: -12px;
      }

      &.wheel-one {
       left: 115px;

       @media (max-width: $tablet) {
        left: 63px;
       }
      }

      &.wheel-two {
       right: 115px;

       @media (max-width: $tablet) {
        right: 63px;
       }
      }
     }
    }

    .road {
     position: absolute;
     bottom: 0;
     height: $line-width;
     background-color: $grey-dark;
     opacity: 0.2;

     &.road-back {
      bottom: 70px;
      width: $desktop-width;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: $tablet) {
       bottom: 45px;
      }
     }

     &.road-front {
      left: -150px;
      width: 150px;
      position: relative;
      bottom: -35px;

      @media (max-width: $tablet) {
       bottom: -20px;
      }

      &::after,
      &::before {
       display: none;
       content: '';
       position: absolute;
       top: 0;
       bottom: 0;
       width: 40px;
       background-color: $grey;

       @media (max-width: $tablet) {
        display: block;
       }
      }

      &::before {
       left: 0;
      }

      &::after {
       right: 0;
      }
     }
    }
   }
  }


 }

 &.work {
  $desktop-width: 350px;
  $mobile-width: 250px;

  .text {
   @media (max-width: 1000px) {
    flex-basis: 100%;
    padding-bottom: 0;
   }
  }

  .animation-wrapper {
   @media (max-width: 1000px) {
    flex-basis: 100%;
   }

   .animation {
    position: relative;
    width: $desktop-width;
    margin: 0 auto;
    overflow: hidden;

    @media (max-width: $tablet) {
     width: $mobile-width;

    }

    .mug {
     position: relative;
     z-index: 1;
     text-align: center;
     padding-top: 15px;

     .steam-wrapper {
      transform: translate(-15px, -15px);

      .steam {
       filter: blur(4px);
      }
     }

     .mug-body {
      width: 50%;

      @media (max-width: $tablet) {
       width: 55%;
      }
     }
    }

    .table {

     .table-line {
      position: absolute;
      height: $line-width;
      background-color: $grey-dark;
      opacity: 0.2;
      width: $desktop-width;

      &:nth-child(1) {
       bottom: 35px;
       left: 0;
      }

      &:nth-child(2) {
       bottom: 0;
       width: 26%;
       left: 0;
      }

      &:nth-child(3) {
       bottom: 0;
       width: 26%;
       right: 0;
      }
     }
    }
   }
  }
 }
}