.covid {
 position: relative;
 background-color: $grey;
 z-index: 1;

 .wrapper {
  @include horizontal-section-space(padding-left);
  @include horizontal-section-space(padding-right);
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: $covid-height;

  @media (max-width: 1200px) {
   height: auto;
   @include text-spacing(padding-top, 20px);
   @include text-spacing(padding-bottom, 20px);
  }

  .text {

   p {
    text-align: center;
    padding: 0;
    @include fw-sm;
    @include p-md;

    span {
     @include fw-xl;
     @include p-md;

    }
   }
  }

 }
}