footer {
 position: relative;
 z-index: 0;
 background-color: $black;
 overflow: hidden;

 .wrapper {
  @include layout(padding-top, 60px);
  @include horizontal-section-space(padding-left);
  @include horizontal-section-space(padding-right);
  @include layout(padding-bottom, 60px);
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;

  .details {
   flex-basis: 40%;

   @media (max-width: $tablet) {
    flex-basis: 100%;
   }

   .text {
    @include text-spacing(padding-top, 15px);

    p {
     @include terms;
     @include text-spacing(padding-bottom, 10px);
     color: $white;

     &:last-child {
      padding: 0;
     }
    }
   }
  }

  .socials {
   display: flex;

   @media (max-width: $tablet) {
    @include text-spacing(padding-top, 20px);
   }

   .icon-wrapper {
    margin-left: 5px;
    transform: scale(0.9);
    transform-origin: bottom right;

    @media (max-width: $tablet) {
     transform: scale(0.8);
     transform-origin: bottom left;
     margin-left: 0;
    }

    .icon {
     background-color: $black;

     svg {
      stroke: $white;
     }
    }

    &:first-child {
     margin: 0;

    }
   }
  }
 }
}