// layout

@mixin layout($property, $value) {
 #{$property}: $value;

 @media (max-width: $desktop-sm) {
  #{$property}: #{$value * 0.9};
 }

 @media (max-width: $tablet) {
  #{$property}: #{$value * 0.8};
 }

 @media (max-width: $mobile) {
  #{$property}: #{$value * 0.7};
 }
}

// width

@mixin width($width) {
 width: $width;

 @media (max-width: $desktop-sm) {
  width: 85%;
 }

 @media (max-width: $tablet) {
  width: 90%;
 }

 @media (max-width: $mobile) {
  width: 100%;
 }
}

// section spacing vertical

@mixin vertical-section-space($property) {
 #{$property}: 120px;

 @media (max-width: $desktop-sm) {
  #{$property}: 100px;
 }

 @media (max-width: $tablet) {
  #{$property}: 90px;
 }

 @media (max-width: $mobile) {
  #{$property}: 60px;
 }
}

// section spacing horizontal

@mixin horizontal-section-space($property) {
 #{$property}: 110px;

 @media (max-width: $desktop-sm) {
  #{$property}: 90px;
 }

 @media (max-width: $tablet) {
  #{$property}: 50px;
 }

 @media (max-width: $mobile) {
  #{$property}: 25px;
 }
}