.nav-desktop {
 position: fixed;
 z-index: 15;
 top: 0;
 left: 0;
 right: 0;
 height: $nav-height;
 background-color: $black;

 * {
  color: $white;
 }

 .wrapper {
  position: relative;
  z-index: 11;
  height: 100%;
  display: flex;
  align-items: center;
  transform: translateY(60px);
  transition: transform 0.3s;
  @include horizontal-section-space(padding-left);
  @include horizontal-section-space(padding-right);

  @media (max-width: $tablet) {
   transform: none !important;
   @include horizontal-section-space(padding-left);
   @include horizontal-section-space(padding-right);
  }

  .content {
   flex-basis: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;

   ul {
    display: flex;
    align-items: center;

    li {
     position: relative;
     @include text-spacing(margin-left, 50px);
     padding: 5px 0;
     overflow: hidden;

     &:first-child {
      margin: 0;
     }

     .line {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: $line-width;
     }

     a {
      @include p-lg;
      @include fw-md;
      cursor: pointer;

      &::after {
       content: '';
       position: absolute;
       bottom: 0;
       left: 0;
       right: 0;
       height: $line-width;
       background-color: $green;
       opacity: 0;
       transform: scale(0);
       transition: all 0.4s;
      }

      &.btn {
       background-color: $green;
       border-color: $green;
       @include fw-lg;

       &::after {
        display: none;
       }
      }

      &:hover {
       &::after {
        opacity: 1;
        transform: scale(1);
       }
      }
     }

     &:not(:last-child) {
      @media (max-width: $tablet) {
       display: none;
      }
     }

     &:last-child {
      .btn {
       display: inline-block;

       @media (max-width: $tablet) {
        display: none;
       }
      }

      .bars-wrapper {
       position: relative;
       display: none;
       width: 30px;
       height: 30px;
       border-radius: 50%;
       cursor: pointer;

       @media (max-width: $tablet) {
        display: block;
       }

       .bars {
        position: absolute;
        z-index: 12;

        top: 50%;
        height: $line-width;
        width: 100%;
        transform: translateY(-50%);

        .bar {
         position: absolute;
         left: 0;
         right: 0;
         top: 0;
         bottom: 0;
         background-color: $white;

         &:nth-child(1) {
          transform: translateY(-8px);
         }

         &:nth-child(3) {
          transform: translateY(8px);
         }
        }
       }
      }
     }
    }
   }
  }
 }

 &.nav-small {
  .wrapper {
   transform: translateY(0);

   @media (max-width: $tablet) {
    transform: none !important;
   }
  }
 }
}

.nav-mobile {
 display: none;
 position: fixed;
 z-index: 10;
 top: $nav-height;
 overflow: hidden;
 left: 0;
 right: 0;
 height: calc(100vh - #{$nav-height});
 transform: translateY(-100%);
 background-color: $black;
 @include vertical-section-space(padding-top);
 @include horizontal-section-space(padding-left);
 @include horizontal-section-space(padding-right);

 @media (max-width: $tablet) {
  display: block;
 }

 ul {
  li {
   margin-bottom: 30px;
   overflow: hidden;

   a {
    transform-origin: top left;
    font-size: 22px;
    line-height: 22px;
    @include fw-md;
    color: $white;
    cursor: pointer;
    display: block;
   }

   &:last-child {
    margin: 0;
   }
  }
 }
}