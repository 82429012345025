.services {
 position: relative;
 z-index: 0;

 .wrapper {
  @include vertical-section-space(padding-top);
  @include horizontal-section-space(padding-left);
  @include horizontal-section-space(padding-right);

  .bucket {
   position: absolute;
   bottom: -5px;
   right: 0;

   .bubble {
    position: absolute;
    border: 1.5px transparentize($color: $grey-dark, $amount: 0.5) solid;
    border-radius: 50%;
    transform-origin: center;
    animation: burst 3s ease-in infinite;

    @keyframes burst {
     0% {
      opacity: 0;
      transform: scale(0.5);
     }

     90% {
      opacity: 1;
      transform: scale(1);
     }

     100% {
      opacity: 0;
     }
    }

    &.sm {
     bottom: 170px;
     right: 155px;
     width: 10px;
     height: 10px;
     animation-delay: 1s;
    }

    &.md {
     bottom: 180px;
     left: 7px;
     width: 12px;
     height: 12px;
    }

    &.lg {
     bottom: 215px;
     right: 195px;
     width: 14px;
     height: 14px;
     animation-delay: 3s;
    }

    &.xl {
     bottom: 205px;
     left: 70px;
     width: 20px;
     height: 20px;
     animation-delay: 2s;

    }
   }

   img {
    width: 350px;

    @media (max-width: $desktop-sm) {
     width: 200px;
    }

   }

   @media (max-width: $tablet) {
    display: none;
   }
  }

  .accordions {
   width: 600px;
   @include vertical-section-space(padding-bottom);

   @media (max-width: $desktop-sm) {
    width: 450px;
   }

   @media (max-width: $tablet) {
    width: 100%;
   }

   .accordion-wrapper {
    margin-bottom: 20px;

    @media (max-width: $tablet) {
     margin-bottom: 15px;
    }

    &:last-child {
     margin: 0;
    }

    .accordion,
    .panel {
     @include text-spacing(padding-left, 20px);
     @include text-spacing(padding-right, 20px);
    }

    .accordion {
     display: flex;
     align-items: center;
     justify-content: space-between;
     background-color: $black;
     border-top-right-radius: $border-radius;
     border-top-left-radius: $border-radius;
     border-bottom-right-radius: $border-radius;
     border-bottom-left-radius: $border-radius;
     transition: ease-in-out 0.4s;
     cursor: pointer;
     @include text-spacing(padding-top, 15px);
     @include text-spacing(padding-bottom, 15px);


     h4 {
      color: $white;
      padding: 0;
     }

     .acc-icon {
      position: relative;
      background-color: $black;
      width: 20px;
      height: 20px;
      overflow: hidden;
      transition: ease-in-out 0.4s;

      .line {
       position: absolute;
       background-color: $green;
       transform-origin: center;
       border-radius: 0.5px;

       &:first-child {
        top: calc(50% - 1px);
        height: $line-width;
        width: 100%;
       }

       &:last-child {
        left: calc(50% - 1px);
        height: 100%;
        width: $line-width;
       }
      }
     }

     &.active {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      .acc-icon {
       transform: rotate(-45deg);
      }
     }
    }

    .panel {
     background-color: $grey;
     max-height: 0;
     overflow: hidden;
     transition: ease-in-out 0.4s;
     border-bottom-right-radius: $border-radius;
     border-bottom-left-radius: $border-radius;

     p {
      @include p-md;
      padding: 0;
      @include text-spacing(margin-top, 15px);
      @include text-spacing(margin-bottom, 15px);
     }
    }
   }
  }
 }
}