header {
 position: relative;
 z-index: 0;
 background-color: $black;
 margin-top: $nav-height;
 height: calc(100vh - #{$nav-height + $covid-height});
 overflow: hidden;

 @media (max-width: $tablet) {
  height: 100vh;
  margin: 0;
 }

 &::before {
  content: '';
  position: absolute;
  top: -$nav-height;
  left: 0;
  right: 0;
  height: $nav-height;
  background-color: $black;

  @media (max-width: $tablet) {
   display: none;
  }
 }

 .wrapper {
  position: relative;
  top: 52%;
  transform: translateY(-50%);
  @include horizontal-section-space(padding-left);
  @include horizontal-section-space(padding-right);

  .header-item {
   opacity: 0;
   transform: translateY(20px);
  }

  .heading {
   width: 700px;

   @media (max-width: $tablet) {
    width: 100%;
   }

   h1 {
    @media (max-width: 330px) {
     font-size: 40px;
     line-height: 40px * 1.3;
    }
   }

   p {
    @include p-lg;
    @include width(85%);
   }

   * {
    color: $white;
   }
  }

  .btns {
   @include text-spacing(margin-top, 20px);

   .btn {
    border-color: $green;
    @include p-lg;

    &:first-child {
     margin-right: 8px;

     @media (max-width: $tablet) {
      margin-right: 6px;
     }
    }
   }
  }
 }

 .graphic {
  position: absolute;
  @include horizontal-section-space(right);
  bottom: -4px;
  display: flex;
  align-items: flex-end;

  @media (max-width: $tablet) {
   bottom: 0;
  }

  .item {
   position: relative;

   &.lime-one {
    z-index: 3;
    margin-right: -1px;

    img {
     @media (max-width: $desktop-sm) {
      display: none;
     }
    }
   }

   &.lime-two {
    z-index: 2;
    margin-right: -40px;

    @media (max-width: 340px) {
     display: none;
    }

    @media (max-width: $desktop-sm) {
     margin: 100px -100px -100px 0;
    }

    img {
     @media (max-width: $desktop-sm) {
      width: auto;
     }
    }
   }

   &.spray-bottle {
    z-index: 1;

    @media (max-width: $desktop-sm) {
     display: none;
    }

    .bottle {
     position: relative;
     z-index: 1;
    }

    .trigger {
     position: absolute;
     top: 50px;
     left: 0px;
     transform-origin: top right;
    }

    .spray {
     position: absolute;
     top: -75px;
     left: -150px;
     display: flex;
     align-items: center;

     .spray-column {
      margin-left: 20px;
      opacity: 0;

      .dot {
       width: 4px;
       height: 4px;
       background-color: transparentize($color: $green, $amount: 0.5);
       margin-bottom: 12px;
       border-radius: 50%;

       &:nth-child(odd) {
        transform: translate(-2px, 3px);
       }

       &:nth-child(even) {
        transform: translate(5px, -4px);
       }

       &:last-child {
        margin: 0;
       }
      }

      &:first-child {
       margin: 0;
      }
     }
    }
   }
  }
 }
}